<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Daily Income Reports</h4>
            </template>
            <template v-slot:body>
              <b-row class="mb-4">
                <b-col md="5">
                  <b-form>
                    <b-form-group label-cols-sm="3"
                      label="Year:"
                      label-for="year">
                      <b-form-input id="year" type="text" v-model="year"  :value="year" maxlength="4"></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="3"
                      label="Month:"
                      label-for="month">
                      <b-form-select
                            v-model="month"
                            plain
                            :options="months"
                            id="month"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="null">Select Month</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label-cols-sm="3"
                      label="Clinic:"
                      label-for="clinic">
                      <b-form-select
                            v-model="clinic"
                            plain
                            :options="clinicOptions"
                            id="month"
                            @change="filterBranches"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="null">Select Clinic</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label-cols-sm="3"
                      label="Branch:"
                      label-for="branch">
                      <b-form-select
                            v-model="branch"
                            plain
                            :options="branchOptions"
                            id="month"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="null">Select Branch</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                  </b-form>
                    <b-button @click="fetchDailyActivityReport()" variant="primary">Generate Report</b-button>
                    <b-button type="button" variant="none" class="iq-bg-danger ml-3">Clear Filter</b-button>
                </b-col>
              </b-row>
              <div class="text-center" v-show="isLoading">
                <b-spinner variant="primary" isLoading={false} label="loading"></b-spinner>
             </div>
             <b-row class="mb-2" v-show="showTable">
                <b-col md="12">
                    <h5 class="card-title">Daily Income Report For {{ currentMonthText() }} {{ year }} <span>[PER 7 DAYS]</span></h5>
                    <h6 class="card-title">{{clinicName}} - {{ branchName }}</h6>
                </b-col>
             </b-row>
             <b-row>
                <b-col md="12" v-show="showTable">
                    <b-table
                        striped
                        hover
                        outlined
                        responsive
                        :sticky-header="stickyHeader"
                        :fields="fields"
                        :items="itemsBy7Days"
                        :filter="filter"
                        >
                        <template v-for="field in fields" v-slot:[`cell(${field.key})`]="{ item }">
                            <template v-if="item.date === 'INTEREST RATE' && field.key.startsWith('payment_')">
                                <!-- Display interest rate as percentage -->
                                <span class="float-right" :key="field.key" :class="{ 'bold-text': item.boldText }">
                                    {{ item[field.key] > 0 ? item[field.key] + '%' : '-' }}
                                </span>
                            </template>
                            <template v-else-if="field.key.startsWith('payment_') || field.key.startsWith('totalPayments')">
                                <!-- Handle payment fields with currency formatting -->
                                <template v-if="field.key.startsWith('totalPayments')">
                                    <span :key="field.key" class="float-right" :class="{ 'bold-text': item.boldText, 'total-column': field.key.startsWith('totalPayments') }">
                                        {{ item[field.key] > 0 ? formatCurrency(item[field.key]) : '' }}
                                    </span>
                                </template>
                                <template v-else-if="field.key.startsWith('payment_')">
                                    <span :key="field.key" class="float-right" :class="{ 'bold-text': item.boldText, 'total-column': field.key.startsWith('totalPayments') }">
                                        {{ item[field.key] > 0 ? formatCurrency(item[field.key]) : '-' }}
                                    </span>
                                </template>
                            </template>
                            <template v-else>
                                <!-- Default handling for other fields -->
                                <template v-if="item.date.includes('|')">
                                    <span :key="field.key" :class="{ 'bold-text': item.boldText }">
                                        {{ formatDateRange(item.date) }}
                                    </span>
                                </template>
                                <template v-else-if="moment(item[field.key], 'YYYY-MM-DD', true).isValid()">
                                    {{ moment(item.date).format('dddd, D MMMM YYYY') }}
                                </template>
                                <template v-else>
                                    <span :key="field.key" :class="{ 'bold-text': item.boldText }">{{ item[field.key] }}</span>
                                </template>
                            </template>
                        </template>
                    </b-table>
                </b-col>
              </b-row>
              <hr>
              <b-row class="mb-2" v-show="showTable">
                <b-col md="12">
                    <h5 class="card-title">Daily Income Report For {{ currentMonthText() }} {{ year }}</h5>
                    <h6 class="card-title">{{clinicName}} - {{ branchName }}</h6>
                </b-col>
             </b-row>
            <b-row>
                <b-col md="12" v-show="showTable">
                    <b-table
                        striped
                        hover
                        outlined
                        responsive
                        :sticky-header="stickyHeader"
                        :fields="fields"
                        :items="itemList"
                        :filter="filter"
                        >
                        <template v-for="field in fields" v-slot:[`cell(${field.key})`]="{ item }">
                            <template v-if="item.date === 'INTEREST RATE' &&  field.key.startsWith('payment_')">
                            <!-- Display interest rate as percentage -->
                                <span class="float-right" :key="field.key" :class="{ 'bold-text': item.boldText }">{{ item[field.key] > 0 ? item[field.key] + '%' : '-' }}</span>
                            </template>
                            <template v-else-if="field.key.startsWith('payment_') || field.key.startsWith('totalPayments')">
                            <!-- Handle payment fields with currency formatting -->
                                <template v-if="field.key.startsWith('totalPayments')">
                                    <span :key="field.key" class="float-right" :class="{ 'bold-text': item.boldText, 'total-column': field.key.startsWith('totalPayments') }">
                                        {{ item[field.key] > 0 ? formatCurrency(item[field.key]) : '' }}
                                    </span>
                                </template>
                                <template v-else-if="field.key.startsWith('payment_')">
                                    <span :key="field.key" class="float-right" :class="{ 'bold-text': item.boldText, 'total-column': field.key.startsWith('totalPayments') }">
                                        {{ item[field.key] > 0 ? formatCurrency(item[field.key]) : '-' }}
                                    </span>
                                </template>
                            </template>
                            <template v-else>
                            <!-- Default handling for other fields -->
                            <template v-if="moment(item[field.key], 'YYYY-MM-DD', true).isValid()">
                                {{ moment(item.date).format('dddd, D MMMM YYYY') }}
                            </template>
                            <template v-else>
                                <span :key="field.key" :class="{ 'bold-text': item.boldText }">{{ item[field.key] }}</span>
                            </template>
                            </template>
                        </template>
                    </b-table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import reports from '../../services/reports'
import branches from '../../services/branches'
import clinics from '../../services/clinics'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
name: 'DailyIncomeReport',
mounted () {
    xray.index()
    this.fetchBranches()
    this.fetchClinics()
},
data () {
    return {
        filter: '',
        fields: [
            { label: 'Date', key: 'date', sortable: false, stickyColumn: true, column: '300px', class: 'date-column' }
        ],

        months: [
            { text: 'January', value: 1 },
            { text: 'February', value: 2 },
            { text: 'March', value: 3 },
            { text: 'April', value: 4 },
            { text: 'May', value: 5 },
            { text: 'June', value: 6 },
            { text: 'July', value: 7 },
            { text: 'August', value: 8 },
            { text: 'September', value: 9 },
            { text: 'October', value: 10 },
            { text: 'November', value: 11 },
            { text: 'December', value: 12 }
        ],
        month: new Date().getMonth() + 1,
        items: [],
        itemsBy7Days: [],
        results: [],
        showCommissionRate: false,
        showTable: false,
        year: moment(new Date()).format('YYYY'),
        expenses: [],
        moment: moment,
        clinicOptions: [],
        branchOptions: [],
        branch: null,
        clinic: null,
        clinicName: '',
        branchName: '',
        isLoading: false,
        stickyHeader: false,
        listOfBranches: []
    }
},
watch: {
    items (value) {
    if (value.length > 0) {
        this.showTable = true
    } else {
        this.showTable = false
    }
    }
},
computed: {
    itemList () {
    return this.items.map(d => {
        let boldText = false // Initialize boldText flag

        if (d?.date === 'TOTAL') {
            d._rowVariant = 'warning' // Set row variant
            boldText = true // Set boldText to true for this row
        } else if (d?.date === 'INTEREST RATE' || d?.date === 'TOTAL INTEREST') {
            d._rowVariant = 'secondary' // Set row variant
            boldText = true // Set boldText to true for this row
        } else if (d?.date === 'GRAND TOTAL') {
            d._rowVariant = 'danger' // Set row variant
            boldText = true // Set boldText to true for this row
        }

        // Return modified object with boldText property
        return { ...d, boldText }
    })
    },
    ...mapGetters({
        userActiveBranch: 'Auth/userActiveBranch',
        userDetails: 'Auth/userDetails'
    })
},
methods: {
    currentMonthText () {
      const monthObject = this.months.find(month => month.value === this.month)
      return monthObject ? monthObject.text : ''
    },
    formatCurrency (value) {
      if (typeof value !== 'number') {
        return value // Handle non-numeric values gracefully
      }
      // Format as currency with PHP and commas
      return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
    },
    async fetchDailyActivityReport () {
        if (this.clinic === null || this.branch === null || this.month === null || this.year === null) {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required'
            })

            return false
        }

        this.clinicName = ''
        const selectedClinic = this.clinicOptions.find(clinic => clinic.value === this.clinic)
        if (selectedClinic) {
            this.clinicName = selectedClinic.text
        }

        this.branchName = ''
        const selectedBranch = this.branchOptions.find(clinic => clinic.value === this.branch)
        if (selectedBranch) {
            this.branchName = selectedBranch.text
        }

        this.showTable = false

        this.isLoading = true

        let data = {
            'year': this.year,
            'month': this.month,
            'branchId': this.branch
        }
        reports.dailyIncomeReport(data).then(response => {
            if (response.data.success) {
                this.results = response.data.data
                this.groupData()
            }
        }).catch(err => console.log(err))
    },
    groupData () {
        // Dynamically add fields based on payment types
        this.results.payment_types.forEach(pt => {
            this.fields.push({ label: pt.name, key: 'payment_' + pt.id, headerClass: 'text-right', sortable: false })
        })

        this.fields.push({ label: 'Total', key: 'totalPayments', headerClass: 'text-right', sortable: false })

        // Get the first and last day of the current month
        const year = this.year // Replace with your actual year
        const month = this.month // Replace with your actual month
        const firstDayOfMonth = moment({ year, month: month - 1 }).startOf('month')
        const lastDayOfMonth = moment({ year, month: month - 1 }).endOf('month')

        // Generate an array of all dates from firstDayOfMonth to lastDayOfMonth
        const dateRange = []
        let currentDate = moment(firstDayOfMonth)
        while (currentDate.isSameOrBefore(lastDayOfMonth)) {
            dateRange.push(currentDate.format('YYYY-MM-DD'))
            currentDate.add(1, 'days')
        }

        // Initialize sumsByDate as an empty object
        const sumsByDate = {}

        // Iterate over each date in the dateRange and initialize sumsByDate
        dateRange.forEach(date => {
            sumsByDate[date] = {}
        })

        // Group items by date
        const groupedData = this.results.generated_data.reduce((acc, item) => {
            const date = item.date
            if (!acc[date]) {
            acc[date] = []
            }
            acc[date].push(item)
            return acc
        }, {})

        // Calculate sums for each date group
        for (let date in groupedData) {
            groupedData[date].forEach(item => {
                this.results.payment_types.forEach(pt => {
                    const paymentKey = 'payment_' + pt.id
                    // Ensure sumsByDate[date] and item[paymentKey] exist before accessing
                    if (sumsByDate[date] && item[paymentKey] !== undefined) {
                        sumsByDate[date][paymentKey] = (sumsByDate[date][paymentKey] || 0) + item[paymentKey]
                    }
                })
            })
        }

        // Prepare transformedData for Vue component
        const paymentTypeKeys = this.results.payment_types.map(pt => 'payment_' + pt.id)
        const transformedData = dateRange.map(date => {
            const dataObject = { date }

            paymentTypeKeys.forEach(key => {
                dataObject[key] = sumsByDate[date][key] || 0 // Default to 0 if sumsByDate[date][key] is undefined
            })

            // Calculate total payments for the date
            dataObject.totalPayments = paymentTypeKeys.reduce((total, key) => {
                return total + (dataObject[key] || 0)
            }, 0)

            return dataObject
        })

        // Calculate total sums across all dates for each payment type
        const totalSums = {}
        paymentTypeKeys.forEach(key => {
            totalSums[key] = transformedData.reduce((sum, dataObject) => {
                return sum + (dataObject[key] || 0)
            }, 0)
        })

        // Add a new row for total sums
        const totalRow = { date: 'TOTAL' }
        let totalRowSum = 0
        paymentTypeKeys.forEach(key => {
            totalRow[key] = totalSums[key]
            totalRowSum += totalSums[key]
        })

        totalRow.totalPayments = totalRowSum
        transformedData.push(totalRow)

        // Add a new row for interest rate
        const interestRow = { date: 'INTEREST RATE' }
        this.results.payment_types.forEach(pt => {
            interestRow['payment_' + pt.id] = pt.interest_rate
        })

        transformedData.push(interestRow)

        const grandTotalRow = { date: 'GRAND TOTAL' }
        const totalInterestRateRow = { date: 'TOTAL INTEREST' }
        let totalInterestSum = 0
        let totalOfGrandTotal = 0
        paymentTypeKeys.forEach(key => {
            if (key.startsWith('payment_')) {
                const paymentTypeId = key.split('_')[1] // Extract payment type ID
                const totalWithoutInterest = totalSums[key] || 0

                // Find the payment type and its interest rate
                const paymentType = this.results.payment_types.find(pt => pt.id === parseInt(paymentTypeId))

                if (paymentType && paymentType.interest_rate) {
                    // Convert interest rate from percentage to decimal
                    const interestRateDecimal = paymentType.interest_rate / 100

                    const interestRate = totalWithoutInterest * interestRateDecimal
                    totalInterestRateRow[key] = interestRate
                    totalInterestSum += interestRate

                    // Subtract interest rate (in decimal form) from total sum
                    const grandTotal = totalWithoutInterest - interestRate
                    grandTotalRow[key] = grandTotal
                    totalOfGrandTotal += grandTotal
                } else {
                    // If no interest rate is defined, simply assign totalWithoutInterest
                    totalInterestRateRow[key] = 0
                    grandTotalRow[key] = totalWithoutInterest
                    totalOfGrandTotal += totalWithoutInterest
                }
            }
        })
        totalInterestRateRow.totalPayments = totalInterestSum
        transformedData.push(totalInterestRateRow)
        grandTotalRow.totalPayments = totalOfGrandTotal
        transformedData.push(grandTotalRow)

        // Assign transformedData to your Vue component's data or state
        this.items = transformedData

        this.generateDatePerWeek(transformedData)

        this.isLoading = false
    },

    formatDateRange (dateRange) {
        const dates = dateRange.split('|').map(date => moment(date.trim(), 'YYYY-MM-DD'))
        const startDate = dates[0]
        const endDate = dates[1]

        const formattedStartDate = startDate.format('MMM DD')
        const formattedEndDate = endDate.format('DD, YYYY')

        return `${formattedStartDate}-${formattedEndDate}`
    },

    generateDatePerWeek (data) {
        const groupedData = []
        const specialRows = data.filter(item => ['TOTAL', 'INTEREST RATE', 'TOTAL INTEREST', 'GRAND TOTAL'].includes(item.date))
        const filteredData = data.filter(item => !['TOTAL', 'INTEREST RATE', 'TOTAL INTEREST', 'GRAND TOTAL'].includes(item.date))

        for (let i = 0; i < filteredData.length; i += 7) {
            const weekData = filteredData.slice(i, i + 7)
            const weekSum = weekData.reduce((acc, cur) => {
                Object.keys(cur).forEach(key => {
                    if (key !== 'date') {
                        acc[key] = (acc[key] || 0) + cur[key]
                    }
                })
                return acc
            }, {})

            weekSum.date = `${weekData[0].date} | ${weekData[weekData.length - 1].date}`
            groupedData.push(weekSum)
        }

        this.itemsBy7Days = [...groupedData, ...specialRows]
    },

    fetchClinics () {
      clinics.getAllClinics().then(response => {
        if (response.data.success) {
          const clinics = response.data.data
          clinics.forEach(branch => {
              this.clinicOptions.push({ value: branch.id, text: branch.name })
         })
        }
      }).catch(err => console.log(err))
    },

    fetchBranches () {
      branches.getAllBranches().then(response => {
        if (response.data.success) {
            this.listOfBranches = response.data.data
        }
      }).catch(err => console.log(err))
    },

    filterBranches () {
        this.branchOptions = []

        const filteredBranches = this.listOfBranches.filter(branch => branch.clinic.id === this.clinic)
        filteredBranches.forEach(branch => {
            this.branchOptions.push({ value: branch.id, text: branch.name })
        })
    }

}
}
</script>

<style scoped>
.total-expenses-amount {
text-align: center;
font-size: 46px;
}

.total-expenses-amount-overhead {
text-align: center;
}

.total-expenses-amount-other {
    text-align: center;
}
.bold-text {
  font-weight: bold;
}
.total-column {
    color: #0607ff;
    font-size: 17px;
    font-weight: bold;
    text-decoration: underline;
}
</style>
