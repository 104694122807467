<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Daily Income Reports per Treatment</h4>
            </template>
            <template v-slot:body>
              <b-row class="mb-4">
                <b-col md="5">
                  <b-form>
                    <b-form-group label-cols-sm="3"
                      label="Year:"
                      label-for="year">
                      <b-form-input id="year" type="text" v-model="year"  :value="year" maxlength="4"></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="3"
                      label="Month:"
                      label-for="month">
                      <b-form-select
                            v-model="month"
                            plain
                            :options="months"
                            id="month"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="null">Select Month</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label-cols-sm="3"
                      label="Clinic:"
                      label-for="clinic">
                      <b-form-select
                            v-model="clinic"
                            plain
                            :options="clinicOptions"
                            id="clinic"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="null">Select Clinic</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                  </b-form>
                    <b-button @click="fetchDailyActivityReport()" variant="primary">Generate Report</b-button>
                    <b-button type="button" variant="none" class="iq-bg-danger ml-3">Clear Filter</b-button>
                </b-col>
              </b-row>
              <div class="text-center" v-show="isLoading">
                <b-spinner variant="primary" isLoading={false} label="loading"></b-spinner>
             </div>
              <b-row class="mb-2" v-show="showTable">
                <b-col md="12">
                    <h5 class="card-title">Daily Income Report per Treatment For {{ currentMonthText() }} {{ year }}</h5>
                    <h6 class="card-title">{{clinicName}}</h6>
                </b-col>
             </b-row>
            <b-row>
                <b-col md="12" v-show="showTable">
                    <b-table
                        striped
                        hover
                        outlined
                        responsive
                        :sticky-header="stickyHeader"
                        :fields="fields"
                        :items="itemList"
                        :filter="filter"
                        >
                        <!-- <template v-slot:cell(service)="{ item }">
                            {{(item.service).replace(/_/g, ' ')}}
                        </template> -->
                        <template v-for="field in fields" v-slot:[`cell(${field.key})`]="{ item }">
                            <template v-if="item.date === 'INTEREST RATE' &&  field.key.startsWith('branch_')">
                            <!-- Display interest rate as percentage -->
                                <span class="float-right" :key="field.key" :class="{ 'bold-text': item.boldText }">{{ item[field.key] > 0 ? item[field.key] + '%' : '-' }}</span>
                            </template>
                            <template v-else-if="field.key.startsWith('branch_') || field.key.startsWith('totalPayments')">
                            <!-- Handle payment fields with currency formatting -->
                                <template v-if="field.key.startsWith('totalPayments')">
                                    <span :key="field.key" class="float-right" :class="{ 'bold-text': item.boldText, 'total-column': field.key.startsWith('totalPayments') }">
                                        {{ item[field.key] > 0 ? formatCurrency(item[field.key]) : '' }}
                                    </span>
                                </template>
                                <template v-else-if="field.key.startsWith('branch_')">
                                    <span :key="field.key" class="float-right" :class="{ 'bold-text': item.boldText, 'total-column': field.key.startsWith('totalPayments') }">
                                        {{ item[field.key] > 0 ? formatCurrency(item[field.key]) : '-' }}
                                    </span>
                                </template>
                            </template>
                            <template v-else>
                            <!-- Default handling for other fields -->
                                <span :key="field.key" :class="{ 'bold-text': item.boldText }">{{ item[field.key].replace(/_/g, ' ') }}</span>
                            </template>
                        </template>
                    </b-table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import reports from '../../services/reports'
import branches from '../../services/branches'
import clinics from '../../services/clinics'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
name: 'DailyIncomeServiceReport',
mounted () {
    xray.index()
    this.fetchBranches()
    this.fetchClinics()
},
data () {
    return {
        filter: '',
        fields: [],

        months: [
            { text: 'January', value: 1 },
            { text: 'February', value: 2 },
            { text: 'March', value: 3 },
            { text: 'April', value: 4 },
            { text: 'May', value: 5 },
            { text: 'June', value: 6 },
            { text: 'July', value: 7 },
            { text: 'August', value: 8 },
            { text: 'September', value: 9 },
            { text: 'October', value: 10 },
            { text: 'November', value: 11 },
            { text: 'December', value: 12 }
        ],
        month: new Date().getMonth() + 1,
        items: [],
        itemsBy7Days: [],
        results: [],
        showCommissionRate: false,
        showTable: false,
        year: moment(new Date()).format('YYYY'),
        expenses: [],
        moment: moment,
        clinicOptions: [],
        branchOptions: [],
        branch: null,
        clinic: null,
        clinicName: '',
        branchName: '',
        isLoading: false,
        stickyHeader: false,
        listOfBranches: []
    }
},
watch: {
    items (value) {
        if (value.length > 0) {
            this.showTable = true
        } else {
            this.showTable = false
        }
    }
},
computed: {
    itemList () {
    return this.items.map(d => {
        let boldText = false // Initialize boldText flag

        if (d?.name === 'TOTAL AMOUNT') {
            d._rowVariant = 'warning' // Set row variant
            boldText = true // Set boldText to true for this row
        } else if (d?.name === 'TOTAL DISCOUNT') {
            d._rowVariant = 'secondary' // Set row variant
            boldText = true // Set boldText to true for this row
        } else if (d?.name === 'TOTAL BALANCES') {
            boldText = true // Set boldText to true for this row
        } else if (d?.name === 'GRAND TOTAL') {
            d._rowVariant = 'danger' // Set row variant
            boldText = true // Set boldText to true for this row
        }

        // Return modified object with boldText property
        return { ...d, boldText }
    })
    },
    ...mapGetters({
        userActiveBranch: 'Auth/userActiveBranch',
        userDetails: 'Auth/userDetails'
    })
},
methods: {
    currentMonthText () {
      const monthObject = this.months.find(month => month.value === this.month)
      return monthObject ? monthObject.text : ''
    },
    formatCurrency (value) {
      if (typeof value !== 'number') {
        return value // Handle non-numeric values gracefully
      }
      // Format as currency with PHP and commas
      return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
    },
    async fetchDailyActivityReport () {
        if (this.clinic === null || this.month === null || this.year === null) {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required'
            })

            return false
        }

        this.clinicName = ''
        const selectedClinic = this.clinicOptions.find(clinic => clinic.value === this.clinic)
        if (selectedClinic) {
            this.clinicName = selectedClinic.text
        }

        // this.showTable = false

        this.isLoading = true

        let data = {
            'year': this.year,
            'month': this.month,
            'clinic': this.clinic
        }
        reports.dailyIncomeTreatmentReport(data).then(response => {
            if (response.data.success) {
                this.results = response.data.data
                console.log(response.data.data)
                this.groupData()
            }
        }).catch(err => console.log(err))
    },
    groupData () {
        // Grouping data by branch_id

        this.fields = [
            { label: 'Income', key: 'name', sortable: false, stickyColumn: true, column: '300px', class: 'date-column' }
        ]

        let branchTotals = {}
        let branchDiscountTotals = {}
        let branchBalances = {}

        Object.entries(this.results.branches).forEach(([key, value]) => {
            // Your logic here
            this.fields.push({ label: value, key: 'branch_' + key, headerClass: 'text-right', sortable: false })
            branchTotals['branch_' + key] = 0
            branchDiscountTotals['branch_' + key] = 0
            branchBalances['branch_' + key] = 0
        })

        let groupedServices = {}

        // Iterate over activity_services
        this.results.activity_services.forEach(service => {
            if (service.amount === null || service.amount === 0) return
            const serviceName = (service.name ?? '').trim().toUpperCase().replace(/\s+/g, '_')
            const branchId = service.branch_id

            if (!groupedServices[serviceName]) {
                // Initialize a new object for the service name if it doesn't exist
                groupedServices[serviceName] = {}
            }

            if (!groupedServices[serviceName][`branch_${branchId}`]) {
                // Initialize the branch total if it doesn't exist
                groupedServices[serviceName][`branch_${branchId}`] = 0
            }

            groupedServices[serviceName][`branch_${branchId}`] += service.amount
        })

         // Calculate discount totals per branch
        this.results.activity_discounts.forEach(discount => {
            const branchId = discount.branch_id
            branchDiscountTotals[`branch_${branchId}`] += parseFloat(discount.amount)
        })

        this.results.activity_balances.forEach(discount => {
            const branchId = discount.branch_id
            branchBalances[`branch_${branchId}`] += parseFloat(discount.balance)
        })

        let groupedArray = []

        Object.keys(groupedServices).forEach(serviceName => {
            let serviceObject = { name: serviceName }

            // Add branch totals dynamically
            Object.keys(groupedServices[serviceName]).forEach(branchKey => {
                serviceObject[branchKey] = groupedServices[serviceName][branchKey]
                branchTotals[branchKey] += groupedServices[serviceName][branchKey]
            })

            groupedArray.push(serviceObject)
        })

        // Add row for total amount per branch
        let totalRow = { name: 'TOTAL AMOUNT' }
        Object.keys(branchTotals).forEach(branchKey => {
            totalRow[branchKey] = branchTotals[branchKey]
        })
        groupedArray.push(totalRow)

        let balancesRow = { name: 'TOTAL BALANCES' }
        Object.keys(branchTotals).forEach(branchKey => {
            balancesRow[branchKey] = branchBalances[branchKey]
        })
        groupedArray.push(balancesRow)

        let discountRow = { name: 'TOTAL DISCOUNT' }
        Object.keys(branchDiscountTotals).forEach(branchKey => {
            discountRow[branchKey] = branchDiscountTotals[branchKey]
        })
        groupedArray.push(discountRow)

        let grandTotalRow = { name: 'GRAND TOTAL' }
        Object.keys(branchTotals).forEach(branchKey => {
            grandTotalRow[branchKey] = (branchTotals[branchKey] - branchDiscountTotals[branchKey]) - branchBalances[branchKey]
        })
        groupedArray.push(grandTotalRow)
        this.items = groupedArray

        this.isLoading = false
    },

    formatDateRange (dateRange) {
        const dates = dateRange.split('|').map(date => moment(date.trim(), 'YYYY-MM-DD'))
        const startDate = dates[0]
        const endDate = dates[1]

        const formattedStartDate = startDate.format('MMM DD')
        const formattedEndDate = endDate.format('DD, YYYY')

        return `${formattedStartDate}-${formattedEndDate}`
    },

    fetchClinics () {
      clinics.getAllClinics().then(response => {
        if (response.data.success) {
          const clinics = response.data.data
          clinics.forEach(branch => {
              this.clinicOptions.push({ value: branch.id, text: branch.name })
         })
        }
      }).catch(err => console.log(err))
    },

    fetchBranches () {
      branches.getAllBranches().then(response => {
        if (response.data.success) {
            this.listOfBranches = response.data.data
        }
      }).catch(err => console.log(err))
    }
}
}
</script>

<style scoped>
.total-expenses-amount {
text-align: center;
font-size: 46px;
}

.total-expenses-amount-overhead {
text-align: center;
}

.total-expenses-amount-other {
    text-align: center;
}
.bold-text {
  font-weight: bold;
}
.total-column {
    color: #0607ff;
    font-size: 17px;
    font-weight: bold;
    text-decoration: underline;
}
</style>
