<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Commission Report</h4>
          </template>
          <template v-slot:body>
            <b-row class="mb-4">
              <b-col md="5">
                <b-form>
                  <b-form-group label-cols-sm="3"
                        label="Dentist"
                        label-for="selectdoctor">
                    <b-form-select v-model="doctor"  plain :options="doctors" id="selectdoctor">
                      <template v-slot:first>
                        <b-form-select-option :value="null">Select Dentist</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label-cols-sm="3"
                    label="From:"
                    label-for="fromDate">
                    <b-form-input id="fromDate" type="date" v-model="fromDate"  :value="fromDate"></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-sm="3"
                    label="To:"
                    label-for="toDate">
                    <b-form-input id="toDate" type="date" v-model="toDate"  :value="toDate"></b-form-input>
                  </b-form-group>
                </b-form>
                  <b-button @click="fetchCommissionReport()" variant="primary">Generate Report</b-button>
                  <b-button type="button" variant="none" class="iq-bg-danger ml-3" @click="clearFilters()">Clear Filter</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-show="showTable">
                <b-table
                  striped
                  hover
                  outlined
                  :fields="fields"
                  :items="items"
                  :filter="filter"
                  :tbody-tr-class="rowClass"
                >
                  <template #cell(id)="row">
                      <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails" title="Show Details"></b-form-checkbox>
                  </template>
                  <template v-slot:cell(doctor)="{ item }">
                    {{ item.doctor.first_name }} {{ item.doctor.last_name }}
                  </template>
                  <template v-slot:cell(activity_date)="{ item }">
                    {{ moment().format('MMMM D, YYYY') }}
                  </template>
                  <template v-slot:cell(action)="{ item }">
                    <b-button @click="generateCommissionReport(item)" title="Print Commission Report" variant="primary"><i class="fa fa-print"> View</i></b-button>
                  </template>
                  <template #row-details="row">
                      <table class="table table-sm table-striped border">
                        <thead>
                          <tr>
                            <th>Control No.</th>
                            <th>PPS No.</th>
                            <th>Patient</th>
                            <th>Commission</th>
                            <th>Additional Commission</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(d, i) in row.item.subDetails" :key="`row-${row.item.date}-${i}`">
                            <td width="15%">{{ d?.control_no }}</td>
                            <td width="15%">{{ d?.pps_no }}</td>
                            <td>{{ d?.patient_name }}</td>
                            <td width="15%" class="text-right">{{ d?.total_commission }}</td>
                            <td width="20%" class="text-right">{{ d?.additional_commission }}</td>
                            <td width="15%" class="text-right">{{ d?.total_commission + d?.additional_commission }}</td>
                          </tr>
                        </tbody>
                      </table>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="release-confirm-modal" size="lg" hide-footer cancel-title="Close" title="Payout breakdown">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(saveRelease)">
          <div class="row" ref="divReleaseCommission">
            <div class="col-12">
              <h4><strong>Here are the payout breakdown for <u>{{ commissionRec.dentistName }}</u> and please confirm and select how do you pay on each item.</strong></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table width="100%">
                <thead>
                  <th>ITEM</th>
                  <th>AMOUNT</th>
                  <th>TO BE PAID BY</th>
                </thead>
                <tbody>
                  <tr :class="{'hasPayout': paymentMapping.dailyRate.amount > 0}">
                    <td>Daily Rate</td>
                    <td>{{ parseFloat(paymentMapping.dailyRate.amount).toFixed(2) }}</td>
                    <td>
                      <div v-if="paymentMapping.dailyRate.amount > 0">
                        <!-- <b-form-group label-cols-sm="4"
                          label="Cash on hand:"
                          label-for="dailyRateCashOnHand">
                          <b-form-input id="dailyRateCashOnHand" type="number" class="selectWhite" v-model="paymentMapping.dailyRate.paymentTypes.cashOnHand"  :value="paymentMapping.dailyRate.paymentTypes.cashOnHand"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-sm="4"
                          label="Petty Cash:"
                          label-for="dailyRatePettyCash">
                          <b-form-input id="dailyRatePettyCash" type="number" class="selectWhite" v-model="paymentMapping.dailyRate.paymentTypes.pettyCash"  :value="paymentMapping.dailyRate.paymentTypes.pettyCash"></b-form-input>
                        </b-form-group> -->
                        <b-form-group label-cols-sm="4"
                          label="Head Office:"
                          label-for="dailyRateHeadOffice">
                          <b-form-input id="dailyRateHeadOffice" type="number" class="selectWhite" v-model="paymentMapping.dailyRate.paymentTypes.headOffice"  :value="paymentMapping.dailyRate.paymentTypes.headOffice"></b-form-input>
                        </b-form-group>
                      </div>
                    </td>
                  </tr>
                  <tr :class="{'hasPayout': paymentMapping.commission.amount > 0}">
                    <td>Commission</td>
                    <td>{{ parseFloat(paymentMapping.commission.amount).toFixed(2) }}</td>
                    <td>
                      <div v-if="paymentMapping.commission.amount > 0">
                        <!-- <b-form-group label-cols-sm="4"
                          label="Cash on hand:"
                          label-for="commissionCashOnHand">
                          <b-form-input id="commissionCashOnHand" type="number" class="selectWhite" v-model="paymentMapping.commission.paymentTypes.cashOnHand"  :value="paymentMapping.commission.paymentTypes.cashOnHand"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-sm="4"
                          label="Petty Cash:"
                          label-for="commissionPettyCash">
                          <b-form-input id="commissionPettyCash" type="number" class="selectWhite" v-model="paymentMapping.commission.paymentTypes.pettyCash"  :value="paymentMapping.commission.paymentTypes.pettyCash"></b-form-input>
                        </b-form-group> -->
                        <b-form-group label-cols-sm="4"
                          label="Head Office:"
                          label-for="commissionHeadOffice">
                          <b-form-input id="commissionHeadOffice" type="number" class="selectWhite" v-model="paymentMapping.commission.paymentTypes.headOffice"  :value="paymentMapping.commission.paymentTypes.headOffice"></b-form-input>
                        </b-form-group>
                      </div>
                    </td>
                  </tr>
                  <tr :class="{'hasPayout': paymentMapping.mealAllowance.amount > 0}">
                    <td>Meal Allowance</td>
                    <td>{{ parseFloat(paymentMapping.mealAllowance.amount).toFixed(2) }}</td>
                    <td>
                      <div v-if="paymentMapping.mealAllowance.amount > 0">
                        <!-- <b-form-group label-cols-sm="4"
                          label="Cash on hand:"
                          label-for="mealAllowanceCashOnHand">
                          <b-form-input id="mealAllowanceCashOnHand" type="number" class="selectWhite" v-model="paymentMapping.mealAllowance.paymentTypes.cashOnHand"  :value="paymentMapping.mealAllowance.paymentTypes.cashOnHand"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-sm="4"
                          label="Petty Cash:"
                          label-for="mealAllowancePettyCash">
                          <b-form-input id="mealAllowancePettyCash" type="number" class="selectWhite" v-model="paymentMapping.mealAllowance.paymentTypes.pettyCash"  :value="paymentMapping.mealAllowance.paymentTypes.pettyCash"></b-form-input>
                        </b-form-group> -->
                        <b-form-group label-cols-sm="4"
                          label="Head Office:"
                          label-for="mealAllowanceHeadOffice">
                          <b-form-input id="mealAllowanceHeadOffice" type="number" class="selectWhite" v-model="paymentMapping.mealAllowance.paymentTypes.headOffice"  :value="paymentMapping.mealAllowance.paymentTypes.headOffice"></b-form-input>
                        </b-form-group>
                      </div>
                    </td>
                  </tr>
                  <tr :class="{'hasPayout': paymentMapping.hazardPay.amount > 0}">
                    <td>Hazard Pay</td>
                    <td>{{ parseFloat(paymentMapping.hazardPay.amount).toFixed(2) }}</td>
                    <td>
                      <div v-if="paymentMapping.hazardPay.amount > 0">
                        <!-- <b-form-group label-cols-sm="4"
                          label="Cash on hand:"
                          label-for="hazardPayCashOnHand">
                          <b-form-input id="hazardPayCashOnHand" type="number" class="selectWhite" v-model="paymentMapping.hazardPay.paymentTypes.cashOnHand"  :value="paymentMapping.hazardPay.paymentTypes.cashOnHand"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-sm="4"
                          label="Petty Cash:"
                          label-for="hazardPayPettyCash">
                          <b-form-input id="hazardPayPettyCash" type="number" class="selectWhite" v-model="paymentMapping.hazardPay.paymentTypes.pettyCash"  :value="paymentMapping.hazardPay.paymentTypes.pettyCash"></b-form-input>
                        </b-form-group> -->
                        <b-form-group label-cols-sm="4"
                          label="Head Office:"
                          label-for="hazardPayHeadOffice">
                          <b-form-input id="hazardPayHeadOffice" type="number" class="selectWhite" v-model="paymentMapping.hazardPay.paymentTypes.headOffice"  :value="paymentMapping.hazardPay.paymentTypes.headOffice"></b-form-input>
                        </b-form-group>
                      </div>
                    </td>
                  </tr>
                  <tr :class="{'hasPayout': paymentMapping.overTime.amount > 0}">
                    <td>Overtime</td>
                    <td>{{ parseFloat(paymentMapping.overTime.amount).toFixed(2) }}</td>
                    <td>
                      <div v-if="paymentMapping.overTime.amount > 0">
                        <!-- <b-form-group label-cols-sm="4"
                          label="Cash on hand:"
                          label-for="overTimeCashOnHand">
                          <b-form-input id="overTimeCashOnHand" type="number" class="selectWhite" v-model="paymentMapping.overTime.paymentTypes.cashOnHand"  :value="paymentMapping.overTime.paymentTypes.cashOnHand"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-sm="4"
                          label="Petty Cash:"
                          label-for="overTimePettyCash">
                          <b-form-input id="overTimePettyCash" type="number" class="selectWhite" v-model="paymentMapping.overTime.paymentTypes.pettyCash"  :value="paymentMapping.overTime.paymentTypes.pettyCash"></b-form-input>
                        </b-form-group> -->
                        <b-form-group label-cols-sm="4"
                          label="Head Office:"
                          label-for="overTimeHeadOffice">
                          <b-form-input id="overTimeHeadOffice" type="number" class="selectWhite" v-model="paymentMapping.overTime.paymentTypes.headOffice"  :value="paymentMapping.overTime.paymentTypes.headOffice"></b-form-input>
                        </b-form-group>
                      </div>
                    </td>
                  </tr>
                  <tr :class="{'hasPayout': paymentMapping.other.amount > 0}">
                    <td>Other</td>
                    <td>{{ parseFloat(paymentMapping.other.amount).toFixed(2) }}</td>
                    <td>
                      <div v-if="paymentMapping.other.amount > 0">
                        <!-- <b-form-group label-cols-sm="4"
                          label="Cash on hand:"
                          label-for="otherCashOnHand">
                          <b-form-input id="otherCashOnHand" type="number" class="selectWhite" v-model="paymentMapping.other.paymentTypes.cashOnHand"  :value="paymentMapping.other.paymentTypes.cashOnHand"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-sm="4"
                          label="Petty Cash:"
                          label-for="otherPettyCash">
                          <b-form-input id="otherPettyCash" type="number" class="selectWhite" v-model="paymentMapping.other.paymentTypes.pettyCash"  :value="paymentMapping.other.paymentTypes.pettyCash"></b-form-input>
                        </b-form-group> -->
                        <b-form-group label-cols-sm="4"
                          label="Head Office:"
                          label-for="otherHeadOffice">
                          <b-form-input id="otherHeadOffice" type="number" class="selectWhite" v-model="paymentMapping.other.paymentTypes.headOffice"  :value="paymentMapping.other.paymentTypes.headOffice"></b-form-input>
                        </b-form-group>
                      </div>
                    </td>
                  </tr>
                  <tr :class="{'hasLate': commissionRec?.late > 0}">
                    <td>Late</td>
                    <td>- {{ parseFloat(commissionRec?.late).toFixed(2) }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <h3>GRAND TOTAL: <u>{{ parseFloat(grandTotalToDentist).toFixed(2) }}</u></h3>

            <b-button class="mt-3" variant="primary" type="submit">Release</b-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal id="commission-report-modal" size="xl" ok-title="Print" @ok.prevent="printCommissionReport()" title="Commission Report ">
        <div class="row" ref="divCommissionReport">
          <div class="col-12">
            <table class="table table-sm table-bordered commission-tbl">
              <thead>
                <tr>
                  <th colspan="5" class="f-w-700 text-center">DENTIST RECORD OF PATIENT</th>
                </tr>
                <tr>
                  <th class="text-center" width="5%">No.</th>
                  <th class="text-center">PATIENT'S NAME</th>
                  <th class="text-center">TREATMENT</th>
                  <th class="text-center">AMOUNT</th>
                  <th class="text-center">COMMISSION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, i) in commissionRec.patientList" :key="`act-${i}`" >
                  <td>{{ d?.ctn+'.' }}</td>
                  <td>{{ d?.isNewLine ? '' : d?.patientName }}</td>
                  <td>{{ d?.treatment }}</td>
                  <td class="text-right mx-2">{{ d?.isNewLine ? '' : (d?.amt ? parseFloat(d?.amt).toFixed(2) : '') }}</td>
                  <td class="text-right mx-2">{{ d?.isNewLine ? '' : (d?.comAmt ? parseFloat(d?.comAmt).toFixed(2) : '') }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" class="fw-bold text-center"><strong>GRAND TOTAL</strong></td>
                  <td class="text-right">{{ parseFloat(commissionRec?.grandTotal).toFixed(2) }}</td>
                  <td class="text-right">{{ parseFloat(commissionRec?.commission).toFixed(2) }}</td>
                </tr>
                <tr>
                  <td colspan="3"><strong>DENTIST NAME:</strong> {{ commissionRec.dentistName }}</td>
                  <td colspan="2"><strong>DATE: {{ commissionRec.formattedDate }}</strong></td>
                </tr>
                <tr>
                  <td colspan="5"><strong>DAILY RATE:</strong> <input id="dailyRate" :disabled="commissionRec.isReleased" type="number" v-model="commissionRec.dailyRate"  value="commissionRec.dailyRate" /></td>
                </tr>
                <tr>
                  <td colspan="5"><strong>COMMISSION:</strong> {{ parseFloat(commissionRec?.commission).toFixed(2) }}</td>
                </tr>
                <tr>
                  <td colspan="5"><strong>MEAL ALLOWANCE:</strong> <input id="mealAllowance" :disabled="commissionRec.isReleased" type="number" v-model="commissionRec.mealAllowance"  value="commissionRec.mealAllowance" /> </td>
                </tr>
                <tr>
                  <td colspan="5"><strong>HAZARD PAY:</strong> <input id="hazardPay" :disabled="commissionRec.isReleased" type="number" v-model="commissionRec.hazardPay"  value="commissionRec.hazardPay" /></td>
                </tr>
                <tr>
                  <td colspan="5"><strong>OVER TIME: </strong><input id="overtime" :disabled="commissionRec.isReleased" type="number" v-model="commissionRec.overTime"  value="commissionRec.overTime" /></td>
                </tr>
                <tr>
                  <td colspan="5"><strong>LATE:</strong> <input id="late" :disabled="commissionRec.isReleased" type="number" v-model="commissionRec.late"  value="commissionRec.late" /></td>
                </tr>
                <tr>
                  <td colspan="5"><strong>OTHER:</strong> <input id="other" :disabled="commissionRec.isReleased" type="number" v-model="commissionRec.other"  value="commissionRec.other" /></td>
                </tr>
                <tr>
                  <td colspan="5"><strong>GRAND TOTAL:</strong> {{ grandTotalToDentist }}</td>
                </tr>
                <tr>
                  <td colspan="3"><strong>PREPARED BY:</strong></td>
                  <td colspan="2"><strong>RECEIVED BY:</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <template #modal-footer="{ ok, cancel }">
          <b-button variant="primary" @click="printCommissionReport">Print</b-button>
          <b-button variant="secondary" @click="cancel">Cancel</b-button>
          <b-button v-show="!commissionRec.isReleased" @click="handleReleaseButton" variant="warning">Release</b-button> <!-- New button -->
        </template>
    </b-modal>
  </b-container>
</template>
<style>
  .commission-tbl {
    font-size: 12px;
    padding: 0 !important;
  }
  .commission-tbl tr td {
    padding: 0 6px !important;
  }
</style>
<script>
import { xray } from '../../config/pluginInit'
import reports from '../../services/reports'
import doctor from '../../services/doctor'
import expense from '../../services/expense'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import html2pdf from 'html2pdf.js'

export default {
  name: 'CommissionReport',
  mounted () {
    xray.index()
    this.fetchDoctorList()
  },
  data () {
    return {
      filter: '',
      moment: moment,
      fields: [
        { label: '', key: 'id', headerClass: 'text-left', sortable: false },
        { label: 'Date', key: 'activity_date', headerClass: 'text-left', sortable: false },
        { label: 'Dentist', key: 'dentist', headerClass: 'text-left', sortable: false },
        { label: 'Commission', key: 'commission', headerClass: 'text-left', sortable: false },
        { label: 'Additionl Commission', key: 'additionalCom', headerClass: 'text-left', sortable: false },
        { label: 'Total Commission', key: 'totalCommission', headerClass: 'text-left', sortable: false },
        { label: 'Action', key: 'action', headerClass: 'text-center', sortable: false }
      ],
      paymentTypes: [
        { value: 'CASHONHAND', text: 'Cash on hand' },
        { value: 'PETTYCASH', text: 'Petty Cash' },
        { value: 'HEADOFFICE', text: 'Head Office' }
      ],
      paymentMapping: {
        dailyRate: {
          amount: 0,
          paymentTypes: {
            cashOnHand: 0,
            pettyCash: 0,
            headOffice: 0
          }
        },
        commission: {
          amount: 0,
          paymentTypes: {
            cashOnHand: 0,
            pettyCash: 0,
            headOffice: 0
          }
        },
        mealAllowance: {
          amount: 0,
          paymentTypes: {
            cashOnHand: 0,
            pettyCash: 0,
            headOffice: 0
          }
        },
        hazardPay: {
          amount: 0,
          paymentTypes: {
            cashOnHand: 0,
            pettyCash: 0,
            headOffice: 0
          }
        },
        overTime: {
          amount: 0,
          paymentTypes: {
            cashOnHand: 0,
            pettyCash: 0,
            headOffice: 0
          }
        },
        other: {
          amount: 0,
          paymentTypes: {
            cashOnHand: 0,
            pettyCash: 0,
            headOffice: 0
          }
        }
      },
      items: [],
      results: [],
      showTable: false,
      doctors: [],
      doctor: null,
      dailyRateType: '',
      commissionType: '',
      mealAllowanceType: '',
      hazardPayType: '',
      overtimeType: '',
      otherType: '',
      fromDate: moment(new Date()).format('YYYY-MM-DD'),
      toDate: moment(new Date()).format('YYYY-MM-DD'),
      commissionRec: {
        patientList: [],
        dentistName: '',
        dailyRate: 0,
        commission: 0,
        mealAllowance: 0,
        hazardPay: 0,
        overTime: 0,
        late: 0,
        other: 0,
        grandTotal: 0,
        date: '',
        preparedBy: '',
        receivedBy: '',
        grandTotalToDentist: 0,
        dentistId: '',
        formattedDate: '',
        isReleased: false,
        payouts: {
          commission: null,
          daily_rate: null,
          hazard_pay: null,
          meal_allowance: null,
          overtime: null
        }
      }
    }
  },
  watch: {
    items (value) {
      if (value.length > 0) {
        this.showTable = true
      } else {
        this.showTable = false
      }
    },
    'userActiveBranch.id': _.debounce(function () {
      this.fetchDoctorList()
    })
  },
  computed: {
    ...mapGetters({
      userActiveBranch: 'Auth/userActiveBranch',
      userDetails: 'Auth/userDetails'
    }),
    totalIncome () {
      const sum = this.totalPaymentBreakdown.reduce((accumulator, object) => {
        return accumulator + object.amount
      }, 0)
      return sum
    },
    totalNetIncome () {
      return this.totalIncome - this.totalExpenses
    },
    grandTotalToDentist () {
      const commission = isNaN(parseFloat(this.commissionRec.commission)) ? 0 : parseFloat(this.commissionRec.commission)
      const dailyRate = isNaN(parseFloat(this.commissionRec.dailyRate)) ? 0 : parseFloat(this.commissionRec.dailyRate)
      const mealAllowance = isNaN(parseFloat(this.commissionRec.mealAllowance)) ? 0 : parseFloat(this.commissionRec.mealAllowance)
      const hazardPay = isNaN(parseFloat(this.commissionRec.hazardPay)) ? 0 : parseFloat(this.commissionRec.hazardPay)
      const overtime = isNaN(parseFloat(this.commissionRec.overTime)) ? 0 : parseFloat(this.commissionRec.overTime)
      const other = isNaN(parseFloat(this.commissionRec.other)) ? 0 : parseFloat(this.commissionRec.other)
      const late = isNaN(parseFloat(this.commissionRec.late)) ? 0 : parseFloat(this.commissionRec.late)
      return (commission + dailyRate + mealAllowance + hazardPay + overtime + other) - late
    }
  },
  methods: {
    rowClass (item) {
      return {
        'releasePayout': item.hasOwnProperty('payouts') && typeof item.payouts === 'object' && Object.keys(item.payouts).length > 0 // Replace 'custom-row-class' with your desired class name
      }
    },
    printCommissionReport () {
        html2pdf(this.$refs.divCommissionReport, {
            margin: 0.1,
            filename: 'commission-report.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        })
    },
    clearCommissionRec () {
        this.commissionRec.patientList = []
        this.commissionRec.dentistName = ''
        this.commissionRec.dentistId = ''
        this.commissionRec.dailyRate = 0
        this.commissionRec.commission = ''
        this.commissionRec.mealAllowance = 0
        this.commissionRec.hazardPay = 0
        this.commissionRec.overTime = 0
        this.commissionRec.late = 0
        this.commissionRec.other = 0
        this.commissionRec.grandTotal = 0
        this.commissionRec.date = ''
        this.commissionRec.preparedBy = ''
        this.commissionRec.receivedBy = ''
        this.commissionRec.grandTotalToDentist = 0
        this.commissionRec.formattedDate = ''
        this.commissionRec.isReleased = false
        this.commissionRec.payouts = {
          commission: null,
          daily_rate: null,
          hazard_pay: null,
          meal_allowance: null,
          overtime: null
        }
    },
    isSumNotEqual (obj) {
      const sum = Object.values(obj.paymentTypes)
        .reduce((acc, curr) => acc + parseFloat(curr), 0)
      return sum !== parseFloat(obj.amount)
    },
    saveRelease () {
      const filteredProperty = Object.keys(this.paymentMapping).filter(key => this.isSumNotEqual(this.paymentMapping[key]))
      if (filteredProperty.length > 0) {
        const filteredPropertiesString = filteredProperty ? filteredProperty.join(', ') : ''
        this.$swal.fire({
          icon: 'error',
          title: 'Oops... Not tallied!',
          text: 'The following items are not tallied to the amount to be paid: ' + filteredPropertiesString.toUpperCase()
        })
        return false
      }
      this.$swal.fire({
            title: 'Are you sure you want to release a payout? (This will be added on expenses when you click yes)',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
          if (result.isConfirmed) {
            let data = {
              'doctor_id': this.commissionRec.dentistId,
              'expense_date': this.commissionRec.date,
              'branch_id': this.userActiveBranch?.branch_id,
              'payment_data': this.paymentMapping,
              'late': this.commissionRec.late
            }
            expense.releasePayout(data)
            .then(response => {
              if (response.data.success) {
                this.$swal(response?.data?.message)
                this.$router.push('/expenses/list')
              }
              this.$swal(response?.data?.message)
            })
          }
        })
    },
    handleReleaseButton () {
      this.paymentMapping.dailyRate.amount = this.commissionRec.dailyRate
      this.paymentMapping.mealAllowance.amount = this.commissionRec.mealAllowance
      this.paymentMapping.commission.amount = this.commissionRec.commission
      this.paymentMapping.hazardPay.amount = this.commissionRec.hazardPay
      this.paymentMapping.overTime.amount = this.commissionRec.overTime
      this.paymentMapping.other.amount = this.commissionRec.other
      this.$bvModal.show('release-confirm-modal')
    },
    generateCommissionReport (item) {
      this.clearCommissionRec()
      var indexCount = 1
      var grandTotalCom = 0
      var grandTotalAmt = 0
      Object.keys(item?.subDetails).forEach((d, i) => {
        var totalAmt = 0
        var totalCom = 0
        var treatments = ''
        // var isNewLine = item.subDetails[d].services.length / 1
        var isNewLine = (item.subDetails[d].services.length + item.subDetails[d].additional_payables.length) / 1
        item.subDetails[d].services.forEach((serviceData, serviceIndex) => {
          totalAmt += parseFloat(serviceData?.amount)
          totalCom += parseFloat(serviceData?.commission_amount)
          // treatments += `${treatments ? ' | ' : ''}` + (serviceData?.service_name + (serviceData?.remarks ? ` - ${serviceData?.remarks} ${item.subDetails[d]?.id ? `#${item.subDetails[d]?.id}` : ''}` : '') + ` (₱ ${(serviceData?.amount).toFixed(2)})`)
          treatments += `${treatments ? ' | ' : ''}` + (serviceData?.service_name + (serviceData?.remarks ? ` - ${serviceData?.remarks}` : '') + ` (₱ ${(serviceData?.amount).toFixed(2)})`)
        })
        item.subDetails[d].additional_payables.forEach((additionalPayable, serviceIndex) => {
          totalAmt += parseFloat(additionalPayable?.amount)
          // treatments += `${treatments ? ' | ' : ''}` + (serviceData?.service_name + (serviceData?.remarks ? ` - ${serviceData?.remarks} ${item.subDetails[d]?.id ? `#${item.subDetails[d]?.id}` : ''}` : '') + ` (₱ ${(serviceData?.amount).toFixed(2)})`)
          treatments += `${treatments ? ' | ' : ''}` + (additionalPayable?.type + (additionalPayable?.description ? ` - ${additionalPayable?.description}` : '') + ` (₱ ${(parseFloat(additionalPayable?.amount)).toFixed(2)})`)
        })
        if (isNewLine > 1) {
          var ctnNum = Number.isInteger(isNewLine) ? isNewLine : Math.round(isNewLine)
          var counter = 0
          for (let index = 1; index <= ctnNum; index++) {
            var trtmts = ''
            treatments.split('|').forEach((d, i) => {
              if ((i >= counter) && (i < (counter + 1))) {
                trtmts += `${trtmts ? ' | ' : ''}` + d
              }
            })
            this.commissionRec.patientList.push({
              ctn: indexCount++,
              patientName: item.subDetails[d]?.patient_name,
              treatment: trtmts,
              amt: totalAmt,
              comAmt: totalCom + parseFloat(item.subDetails[d]?.additional_commission),
              isNewLine: index !== 1
            })
            counter += 1
          }
        } else {
          this.commissionRec.patientList.push({
            ctn: indexCount++,
            patientName: item.subDetails[d]?.patient_name,
            treatment: treatments,
            amt: totalAmt,
            comAmt: totalCom + parseFloat(item.subDetails[d]?.additional_commission),
            isNewLine: false
          })
        }
          grandTotalAmt += totalAmt
          grandTotalCom += totalCom + parseFloat(item.subDetails[d]?.additional_commission)
      })
      if (indexCount < 30) {
        for (let index = indexCount; index <= 30; index++) {
          this.commissionRec.patientList.push({ ctn: index, patientName: '', treatment: '', amt: '', comAmt: '' })
        }
      }
        this.commissionRec.dentistName = item?.dentist
        this.commissionRec.isReleased = item.hasOwnProperty('payouts') && typeof item.payouts === 'object' && Object.keys(item.payouts).length > 0
        this.commissionRec.payouts = item.payouts
        this.commissionRec.dentistId = item?.dentistId
        this.commissionRec.dailyRate = item?.dailyRate
        this.commissionRec.commission = (grandTotalCom).toFixed(2)
        this.commissionRec.mealAllowance = item?.mealAllowance
        this.commissionRec.hazardPay = item?.hazardPay

        let overtime = 0
        let other = 0
        if (this.commissionRec.isReleased) {
          if (item.payouts.payment_data) {
            const paymentData = JSON.parse(item.payouts.payment_data)
            overtime = paymentData.overTime ? paymentData.overTime.amount : 0
            other = paymentData.other ? paymentData.other.amount : 0
          }
        }
        this.commissionRec.overTime = overtime
        this.commissionRec.late = item.payouts?.late ?? 0
        this.commissionRec.other = other
        this.commissionRec.grandTotal = grandTotalAmt
        this.commissionRec.formattedDate = moment(item?.date).format('MMMM D, YYYY')
        this.commissionRec.date = moment(item?.date).format('YYYY-MM-DD')
        this.commissionRec.preparedBy = this.userDetails?.firstname + ' ' + this.userDetails?.middlename + ' ' + this.userDetails?.lastname
        this.commissionRec.receivedBy = ''
        this.commissionRec.grandTotalToDentist = grandTotalCom + item?.dailyRate + item.mealAllowance
        this.$bvModal.show('commission-report-modal')
    },
    clearFilters () {
      this.doctor = null
      this.fromDate = moment(new Date()).format('YYYY-MM-DD')
      this.toDate = moment(new Date()).format('YYYY-MM-DD')
      this.items = []
    },
    fetchDoctorList () {
      doctor.getAllDocsByBranch(this.userActiveBranch?.branch_id).then(response => {
        if (response.data.success) {
          const doctors = response.data.data
          this.doctors = []
          doctors.forEach(doctor => {
            this.doctors.push({ value: doctor.id, text: doctor.first_name + ' ' + doctor.last_name })
          })
        }
      }).catch(err => console.log(err))
    },
    async fetchCommissionReport () {
      if (this.fromDate > this.toDate) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'From date must be less than or equal to To Date'
        })
        return false
      }

      if (!this.doctor) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select a dentist'
        })
        return false
      }
      const payload = {
        'from': this.fromDate,
        'to': this.toDate,
        'doctor': this.doctor
      }

      reports.commissionReport(payload).then(({ data }) => {
        if (data.success) {
          var itemList = []
          const releasePayouts = data.data.release_payouts
          Object.keys(data.data.activities).forEach((d, i) => {
            let commission = 0
            let additionalCom = 0
            let dentistName = ''
            let dailyRate = 0
            let mealAllowance = 0
            let hazardPay = 0
            let dentistId = ''
            let payouts = {}
            Object.keys(data.data.activities[d]).forEach((index) => {
              commission += data.data.activities[d][index].total_commission
              additionalCom += data.data.activities[d][index].additional_commission
              dentistName = data.data.activities[d][index]?.doctor_name
              dailyRate = data.data.activities[d][index]?.salary_rate
              mealAllowance = data.data.activities[d][index]?.meal_allowance
              hazardPay = data.data.activities[d][index]?.hazard_pay
              dentistId = data.data.activities[d][index]?.doctor_id
              payouts = releasePayouts.find(item => item.expense_date === data.data.activities[d][index].activity_date)
            })

            itemList.push({ date: d, dentist: dentistName, dentistId, commission, payouts, additionalCom, dailyRate, mealAllowance, hazardPay, totalCommission: (commission + additionalCom), subDetails: data.data.activities[d] })
          })
          this.items = itemList
        }
      }).catch(err => console.log(err))
    }
  }
}
</script>
